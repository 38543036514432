import clsx from "clsx";
import React from "react";
import Link from "./Navigation/Link";
import Tooltip from "./Tooltip/Tooltip";

type Props = {
  title?: string;
  href?: string;
  className?: string;
  external?: boolean;
  tooltip?: string;
  prose?: boolean;
};

const Card: React.FC<Props> = ({
  children,
  href,
  title,
  className,
  tooltip,
  external = true,
  prose = true,
}) => {
  return (
    <div className={`rounded-xl bg-white dark:bg-slate-800 p-4 shadow-lg ${className ?? ""}`}>
      <div className="mb-2 flex justify-between">
        <div className="text-lg font-bold">
          {href ? (
            <Link href={href} external={external} type="hover">
              {title}
            </Link>
          ) : (
            title
          )}
        </div>
        {tooltip && (
          <div>
            <Tooltip content={tooltip} />
          </div>
        )}
      </div>
      <div
        className={clsx({
          "prose max-w-none dark:prose-invert": prose,
          "not-prose": !prose,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
