import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import { bitcoinsBooks } from "../../common/data/content/bitcoin-books";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";
import LinkList from "../../components/UI-Elements/Lists/LinkList";

const books = bitcoinsBooks.map((book) => ({
  href: book.href,
  title: (
    <div className="py-2">
      <span className="block font-bold mb-1">{book.title}</span>
      <span>Author: {book.author}</span>
    </div>
  ),
  external: true,
}));
const title = "Liste mit Büchern";

const WissenIndex: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["bitcoin", "buch"]} title={title} />
      <Title icon="📚">{title}</Title>
      <p className="text-center">Steige mit dem richtigen Buch noch tiefer in das Thema ein.</p>
      <Section>
        <Title level={2}>Rund um Bitcoin</Title>
        <Card prose={false}>
          <LinkList links={books} />
        </Card>
      </Section>
    </Layout>
  );
};

export default WissenIndex;