export type Book = {
  href: string;
  title: string;
  author: string;
  tags?: string[];
};

export const bitcoinsBooks: Book[] = [
  {
    title: "Der Bitcoin Standard – Die dezentrale Alternative zum Zentralbankensystem",
    author: "Saifedean Ammous",
    href: "https://www.amazon.de/Bitcoin-Standard-Die-dezentrale-Alternative-Zentralbankensystem/dp/3982109507/ref=sr_1_1?keywords=bitcoin+standard&qid=1643746397&sprefix=bitcoin+sta%2Caps%2C142&sr=8-1",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin verstehen – Bitcoin für Einsteiger",
    author: "Jonas Hofmeister",
    href: "https://www.amazon.de/gp/product/B08QWNWBFW/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B08QWNWBFW&linkCode=as2&tag=bcyber-21&linkId=a307acb67b7d091c0aad1c64867fa8cb",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin – Die verrückte Geschichte vom Aufstieg eines neuen Geldes",
    author: "Christoph Bergmann",
    href: "https://www.amazon.de/gp/product/3981988604/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3981988604&linkCode=as2&linkId=f8c17e7676265c255e096f4dafa24c1f",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin entdecken – Wie die Technologie hinter dem ersten knappen und dezentralisierten Geld funktioniert",
    author: "Yan Pritzker",
    href: "https://aprycot.media/shop/bitcoin-entdecken/",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin Geld – Eine Geschichte über die Entdeckung von gutem Geld in Bitdorf",
    author: "Michael Caras",
    href: "https://www.amazon.de/gp/product/0578515113/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=0578515113&linkCode=as2&linkId=1a13091c0392560ea28fe55e06f77c9d",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Ignorieren auf eigene Gefahr – Die neue dezentrale Welt von Bitcoin und Blockchain",
    author: "Pascal Hügli, Yonghand Lee, Richard Dettling",
    href: "https://www.amazon.de/gp/product/3952510807/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3952510807&linkCode=as2&linkId=91cd7fd90dfc6cf5dc2d539e3c904dd1",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Why buy Bitcoin – Investing today in the money of tomorrow",
    author: "Andy Edstrom",
    href: "https://www.amazon.de/gp/product/1733219609/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=1733219609&linkCode=as2&tag=bcyber-21&linkId=68ec824a7c2ae2e74e992d4c839fdc05",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Digital Gold: Bitcoin and the Inside Story of the Misfits and Millionaires Trying to Reinvent Money",
    author: "Nathaniel Popper",
    href: "https://www.amazon.de/gp/product/006236250X/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=006236250X&linkCode=as2&tag=bcyber-21&linkId=38b0420f440b2a755c688fb6b9380371",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin – Geld ohne Staat",
    author: "Aaron König",
    href: "https://www.amazon.de/gp/product/3898799115/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3898799115&linkCode=as2&tag=bcyber-21&linkId=bc56e6881c4642c2f80dc43c56d0acbb",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin & Co.: Der Praxis-Ratgeber für Anfänger – 3 Schritte zur finanziellen Freiheit",
    author: "Anita Posch",
    href: "https://www.amazon.de/gp/product/3950459138/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3950459138&linkCode=as2&tag=bcyber-21&linkId=e2f3c0f21270aa64eca4b0834bb35f18",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin für Anfänger – Ultimativer Guide zu Bitcoin und Blockchain",
    author: "„Bitcoin-Elite“",
    href: "https://www.amazon.de/gp/product/B0784KW8L3/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B0784KW8L3&linkCode=as2&tag=bcyber-21&linkId=36e4030dfcdea16474d032c5b82d971c",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "Bitcoin-Milliardäre: Eine wahre Geschichte über Genie, Verrat und Genugtuung",
    author: "Ben Mezrich",
    href: "https://www.amazon.de/gp/product/3868817824/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3868817824&linkCode=as2&tag=bcyber-21&linkId=cca5662aac5887c1c90917ff320e992a",
    tags: ['bitcoin', 'easy'],
  },
  {
    title: "21 Lektionen – Meine Reise in den Bitcoin Kaninchenbau",
    author: "Der Gigi",
    href: "https://aprycot.media/shop/21-lektionen/",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "Bitcoins verwahren und vererben – Ein praktischer Ratgeber",
    author: "Marc Steiner",
    href: "https://aprycot.media/shop/bitcoins-verwahren-und-vererben/",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "The Blocksize War",
    author: "Jonathan Bier",
    href: "https://www.amazon.de/gp/product/B08YQMC2WM/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=B08YQMC2WM&linkCode=as2&tag=bcyber-21&linkId=5870691ccea2027429bca3c0aee1b5d7",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "Bitcoin – Selbstbestimmung durch Mathematik – Sonderedition",
    author: "Knut Svanholm",
    href: "https://aprycot.media/shop/bitcoin/?ref=blocktrainer",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "Besteuerung und Bilanzierung von Bitcoin & Co. – Strategien, Steuertipps, Steuerwissen zur direkten Umsetzung",
    author: "Sven Kamchen",
    href: "https://www.amazon.de/gp/product/3955546195/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3955546195&linkCode=as2&tag=bcyber-21&linkId=09dcaa1219c2bfd985613955cb5f307b",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "Cryptocoins – Investieren in digitale Währungen",
    author: "Aaron König",
    href: "https://www.amazon.de/gp/product/3959720645/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3959720645&linkCode=as2&tag=bcyber-21&linkId=f805e330401db75c931cb0c7223c705f",
    tags: ['bitcoin', 'altcoins', 'advanced'],
  },
  {
    title: "Die dezentrale Revolution – Wie Bitcoin und die Blockchain-Technologie Wirtschaft und Gesellschaft verändern",
    author: "Aaron König",
    href: "https://www.amazon.de/gp/product/3959721668/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3959721668&linkCode=as2&tag=bcyber-21&linkId=bbce2dbf1c235a008b31641902a46ca9",
    tags: ['bitcoin', 'advanced'],
  },
  {
    title: "Bitcoin & Blockchain – Grundlagen und Programmierung: Die Blockchain verstehen, Anwendungen entwickeln",
    author: "Andreas Antonopoulos",
    href: "https://www.amazon.de/gp/product/3960090714/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=3960090714&linkCode=as2&tag=bcyber-21&linkId=771874c1a325c42025878af5080c6b71",
    tags: ['bitcoin', 'expert'],
  },
  {
    title: "Bitcoin begreifen",
    author: "Kalle Rosenbaum",
    href: "https://www.amazon.de/gp/product/9949742986/ref=as_li_tl?ie=UTF8&camp=1638&creative=6742&creativeASIN=9949742986&linkCode=as2&tag=bcyber-21&linkId=56fb1fa0775e7413171f924389bc4b05",
    tags: ['bitcoin', 'expert'],
  },
  // {
  //   title: "",
  //   author: "",
  //   link: "",
  //   tags: ['bitcoin', 'advanced'],
  // },
];
