import { Popover } from "@meikidd/react-tiny-popover";
import React from "react";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";

type Props = {
  defaultOpen?: boolean;
  content: string;
};

const Tooltip: React.FC<Props> = ({ content, children, defaultOpen = false, ...others }) => {
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Popover
        isOpen={open}
        positions={["top", "bottom", "left", "right"]} // preferred positions by priority
        content={
          <div className="z-10 py-2 px-3 max-w-xs text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 tooltip dark:bg-gray-700">
            {content}
          </div>
        }
        {...others}
      >
        {children ? (
          <>{children}</>
        ) : (
          <div className="cursor-help">
            <AiOutlineInfoCircle size="20" />
          </div>
        )}
      </Popover>
    </div>
  );
};

export default Tooltip;
