import React from "react";
import Link from "../Navigation/Link";

export type LinkItem = {
  href: string;
  title: string | React.ReactNode;
  external?: boolean;
}

type Props = {
  links: LinkItem[]
}

const LinkList: React.FC<Props> = ({ links }) => {
  return (
    <ul className="list-disc list-outside ml-4">
      {links.map((link, index) => (
        <li key={index}>
          <Link href={link.href} external={link.external}>{link.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
